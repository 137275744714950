import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function Area() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Вакансии`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title='Образование'
      />

      <section className='flex flex-col items-center '>
        <h1 className=' text-center text-2xl uppercase text-green-800 font-bold mb-4'>
          МЕЖДУНАРОДНОЕ СОТРУДНИЧЕСТВО
        </h1>
        <p className='p-4 text-xl bg-gray-300 rounded-sm my-2 text-center'>
          Заключенные и планируемые к заключению договоры с иностранными и (или)
          международными организациями по вопросам образования и науки -
          отсутствуют.
        </p>
        <p className='p-4 text-xl bg-gray-300 rounded-sm my-2 text-center'>
          Международная аккредитация образовательных программ - отсутствует.
        </p>
      </section>
    </Layout>
  );
}

export default Area;
